/* eslint-disable */

import React from 'react';
import Loading from 'dan-components/Loading';
import loadable from '../utils/loadable';

// Pages
export const DashboardPage = loadable(
  () => import('./Pages/Dashboard/DashboardPage'),
  {
    fallback: <Loading />,
  }
);

export const Users = loadable(() => import('./Pages/Users/Users'), {
  fallback: <Loading />,
});
export const CampaignFlow = loadable(
  () => import('./Pages/Campaigns/CampaignFlow'),
  {
    fallback: <Loading />,
  }
);

export const Workspaces = loadable(
  () => import('./Pages/Workspaces/WorkspacesLists'),
  {
    fallback: <Loading />,
  }
);
export const CampaignsPage = loadable(
  () => import('./Pages/Campaigns/CampaignsPage'),
  {
    fallback: <Loading />,
  }
);
export const Profile = loadable(() => import('./Pages/UserProfile'), {
  fallback: <Loading />,
});
export const BlankPage = loadable(() => import('./Pages/BlankPage'), {
  fallback: <Loading />,
});

export const CampaignAnalyticsPage = loadable(
  () => import('./Pages/Analytics/CampaignAnalyticsPage'),
  {
    fallback: <Loading />,
  }
);

export const CampaignsAnalyticsPage = loadable(
  () => import('./Pages/Analytics/CampaignsAnalyticsPage'),
  {
    fallback: <Loading />,
  }
);

export const ResourcesManagement = loadable(
  () => import('./Pages/Resources/ResourcesManagement'),
  {
    fallback: <Loading />,
  }
);

export const ResourcesPage = loadable(
  () => import('./Pages/Resources/ResourcesPage'),
  {
    fallback: <Loading />,
  }
);

export const OrganizationsPage = loadable(
  () => import('./Pages/Organizations/OrganizationsPage'),
  {
    fallback: <Loading />,
  }
);

export const DatabaseManagerPage = loadable(
  () => import('./Pages/DatabaseManager/DatabaseManagerPage'),
  {
    fallback: <Loading />,
  }
);

export const OrganizationSA = loadable(
  () => import('./Pages/Organizations/OrganizationDetail/OrganizationSA'),
  {
    fallback: <Loading />,
  }
);

export const Organization = loadable(
  () => import('./Pages/Organization/Organization'),
  {
    fallback: <Loading />,
  }
);

export const RenderDataPreview = loadable(
  () => import('./UiElements/Campaigns/RenderDataPreview'),
  {
    fallback: <Loading />,
  }
);

// Other
export const NotFound = loadable(() => import('./NotFound/NotFound'), {
  fallback: <Loading />,
});
export const NotFoundDedicated = loadable(
  () => import('./Pages/Standalone/NotFoundDedicated'),
  {
    fallback: <Loading />,
  }
);
export const Error = loadable(() => import('./Pages/Error'), {
  fallback: <Loading />,
});

