export default {
  SUPER_ADMIN: [
    {
      key: 'organizations',
      name: 'Organizations',
      linkParent: '/organizations',
      icon: 'ion-ios-home-outline',
    },
    // database-manager url - turn on here
    // {
    //   key: 'database-manager',
    //   name: 'Database Manager',
    //   linkParent: '/database-manager',
    //   icon: 'ion-ios-list-outline',
    // },
  ],
  ORGANIZATION_ADMIN: [
    {
      key: 'dashboard',
      name: 'Dashboard',
      linkParent: '/',
      icon: 'ion-ios-home-outline',
    },
    {
      key: 'campaigns',
      name: 'Campaigns',
      linkParent: '/campaigns',
      icon: 'ion-ios-film-outline',
    },
    {
      key: 'analytics',
      name: 'Analytics',
      linkParent: '/analytics',
      icon: 'ion-ios-pie-outline',
    },
    {
      key: 'users',
      name: 'Users',
      linkParent: '/users',
      icon: 'ion-ios-people-outline',
    },
    // {
    //   key: 'organization',
    //   name: 'Organization',
    //   linkParent: '/organization',
    //   icon: 'ion-ios-briefcase-outline',
    // },
    {
      key: 'workspaces',
      name: 'Workspaces',
      linkParent: '/workspaces',
      icon: 'ion-ios-list-outline',
    },
  ],

  DATA_MANAGER: [
    {
      key: 'dashboard',
      name: 'Dashboard',
      linkParent: '/',
      icon: 'ion-ios-home-outline',
    },
    {
      key: 'campaigns',
      name: 'Campaigns',
      linkParent: '/campaigns',
      icon: 'ion-ios-film-outline',
    },
    {
      key: 'analytics',
      name: 'Analytics',
      linkParent: '/analytics',
      icon: 'ion-ios-pie-outline',
    },
  ],

  ARTIST: [
    {
      key: 'dashboard',
      name: 'Dashboard',
      linkParent: '/',
      icon: 'ion-ios-home-outline',
    },
    {
      key: 'campaigns',
      name: 'Campaigns',
      linkParent: '/campaigns',
      icon: 'ion-ios-film-outline',
    },
  ],

  STATS_MANAGER: [
    {
      key: 'analytics',
      name: 'Analytics',
      linkParent: '/',
      icon: 'ion-ios-pie-outline',
    },
  ],
  DEFAULT: [
    {
      key: 'dashboard',
      name: 'Dashboard',
      linkParent: '/',
      icon: 'ion-ios-home-outline',
    },
  ],
};
