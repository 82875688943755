import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useStyles from './papperStyle-jss';

const PapperBlock = (props) => {
  const { classes, cx } = useStyles();
  const {
    title,
    desc,
    children,
    whiteBg,
    noMargin,
    colorMode,
    overflowX,
    icon,
    noTitle,
  } = props;
  return (
    <>
      {!noTitle ? (
        <Paper
          className={cx(
            classes.root,
            noMargin && classes.noMargin,
            colorMode && classes.colorMode
          )}
          elevation={0}
        >
          <div className={classes.descBlock}>
            <span className={classes.iconTitle}>
              <i className={icon} />
            </span>
            <div className={classes.titleText}>
              <Typography variant="h6" component="h2" className={classes.title}>
                {title}
              </Typography>
              <Typography component="p" className={classes.description}>
                {desc}
              </Typography>
            </div>
          </div>

          <section
            className={cx(
              classes.content,
              whiteBg && classes.whiteBg,
              overflowX && classes.overflowX
            )}
          >
            {children}
          </section>
        </Paper>
      ) : (
        <section
          className={cx(
            classes.content,
            whiteBg && classes.whiteBg,
            overflowX && classes.overflowX
          )}
        >
          {children}
        </section>
      )}
    </>
  );
};

PapperBlock.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  whiteBg: PropTypes.bool,
  colorMode: PropTypes.bool,
  noMargin: PropTypes.bool,
  overflowX: PropTypes.bool,
  noTitle: PropTypes.bool,
};

PapperBlock.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: false,
  overflowX: false,
  noTitle: false,
  icon: 'ion-bookmark',
};

export default PapperBlock;
