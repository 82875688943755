export default {
  BLACK0: '#000000',
  BLACK17: '#2b2b2b',
  BLACK25: '#404040',
  BLACK50: '#808080',
  BLACK70: '#b3b3b3',
  BLACK80: '#cccccc',
  BLACK92: '#ebebeb',
  BLACK96: '#f5f5f5',
  BLACK98: '#fafafa',
  WHITE: '#ffffff',

  RED_LIGHT: '#FFEBEE',
  RED_MIDDLE: '#e57373',
  RED_DARK: '#E53935',
  GREEN: '#81c784',
  GREEN_DARK: '#336035',
  GREEN_GREY: '#607D8B',
  GREY: '#616161',
  BLUE: '#64b5f6',
  YELLOW: '#ffb74d',
  PURPLE: '#9575CD',

  YELLOW_ANALYTICS: 'rgba(243, 147, 12, 1)',
  GREEN_GRAY_ANALYTICS: 'rgba(0, 150, 136, 0.8)',
  BLUE_ANALYTICS: 'rgba(3, 169, 244, 0.8)',
};
