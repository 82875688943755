import React, { useState, useEffect } from 'react';
import { PapperBlock } from 'dan-components';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import axios from 'axios';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select } from '@mui/material';
import Box from '@mui/material/Box';
import { useAuth0 } from 'config/react-auth0-spa';
import * as Yup from 'yup';
import { tss } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import CopyValue from '../Common/CopyValue';
import Snackbar from '../Snackbar/Snackbar';

const useStyles = tss.create(({ theme }) => ({
  deleteButton: {
    margin: '35px 0 0 0',
  },
  dialogContent: {
    padding: '0px',
  },
  paperBlock: {
    margin: '0px',
  },
  padding: { padding: '8px' },
  selectWidth: {
    minWidth: '75%',
  },
  campaignMode: {
    paddingTop: '25px',
  },
  right: {
    float: 'right',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  campaignKey: {
    margin: '20px 0 0 0',
  },
  form: {
    paddingTop: '10px',
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
}));

const CampaignSettingsDialog = (props) => {
  const { campaign, getCampaignDataRequest } = props;
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const { getTokenSilently } = useAuth0();
  const [copyMessage, setCopyMessage] = useState('');
  const [copyStatus, setCopyStatus] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    variant: 'success',
    status: null,
  });

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setCopyMessage('');
    setCopyStatus('');
  };

  const onUpdateWorkspace = async () => {
    setOpenSnackbar({
      ...openSnackbar,
      open: true,
      message: 'Workspace updated successfully',
      variant: 'success',
    });
  };

  const UpdateWorskpace = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    workspaceId: Yup.string(),
  });

  const defaultValues = {
    name: campaign.name || '',
    workspace: campaign.workspaceId || '',
  };

  const { handleSubmit, control } = useForm({
    defaultValues,
    resolver: yupResolver(UpdateWorskpace),
  });

  const editCampaignSettingsRequest = async (values) => {
    try {
      const token = await getTokenSilently();
      const requestBody = {
        workspaceId: values.workspace,
        campaignId: campaign.campaignId,
        name: values.name,
      };
      await axios.put(process.env.CAMPAIGNS_URL, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      handleCloseDialog();
      onUpdateWorkspace();
      getCampaignDataRequest();
    } catch (error) {
      setOpenSnackbar({
        ...openSnackbar,
        open: true,
        message: error.message,
        variant: 'error',
        status: error.status,
      });
    }
  };

  const getWorkspacesRequest = async () => {
    try {
      const token = await getTokenSilently();

      const response = await axios.get(process.env.WORKSPACES_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        await setWorkspaces(response.data);
      }
    } catch (error) {
      setOpenSnackbar({
        ...openSnackbar,
        open: true,
        message: error.message,
        variant: 'error',
        status: error.status,
      });
    }
  };

  const onSubmit = async (values) => {
    editCampaignSettingsRequest(values);
  };

  useEffect(() => {
    getWorkspacesRequest();
  }, []);

  const handleCopyButton = () => {
    setCopyMessage('Copied successfully');
    setCopyStatus('success');
  };

  return (
    <div>
      <Grid container justify="left" gap="10px" alignItems="center">
        <span onClick={handleClickOpenDialog}>
          <EditIcon sx={{ color: '#607D8B', cursor: 'pointer' }} />{' '}
          <a>Campaign settings</a>
        </span>
        <Dialog
          fullWidth
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className={classes.dialogContent}>
            <PapperBlock
              title="Campaign settings"
              noMargin
              desc=""
              icon="ion-ios-gear-outline"
            >
              <div className={classes.padding}>
                <div className={classes.form}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        {...field}
                        type="text"
                        label="Name"
                        sx={{ marginBottom: '15px' }}
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                  <FormLabel
                    component="legend"
                    style={{ marginBottom: '15px' }}
                  >
                    Move this campaign to the different workspace
                  </FormLabel>
                  <Controller
                    name="workspace"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel shrink id="workspace-label">
                          Workspace
                        </InputLabel>
                        <Select
                          fullWidth
                          {...field}
                          type="text"
                          label="Workspace"
                          labelId="workspace-label"
                          name="workspace"
                          error={!!fieldState.error}
                        >
                          {workspaces &&
                            workspaces.map((workspace) => (
                              <MenuItem
                                key={workspace.workspaceId}
                                value={workspace.workspaceId}
                              >
                                {workspace.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Grid container className={classes.campaignKey}>
                    <Grid item xs={12}>
                      <FormLabel component="legend">Campaign key</FormLabel>
                      <CopyValue
                        value={props.campaign.campaignKey}
                        tooltipValue="Campaign key"
                        copyButton={{
                          icon: 'ion-ios-copy',
                          tooltip: 'Copy',
                        }}
                        copyButtonOnClick={handleCopyButton}
                        copyMessage={copyMessage}
                        copyStatus={copyStatus}
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Box margin={1} className={classes.right}>
                      <Button onClick={handleCloseDialog} color="primary">
                        Close
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        // disabled
                      >
                        Save
                      </Button>
                    </Box>
                  </DialogActions>
                </div>
              </div>
            </PapperBlock>
          </DialogContent>
        </Dialog>
      </Grid>
      <Snackbar
        open={openSnackbar.open}
        onClose={() =>
          setOpenSnackbar({
            ...openSnackbar,
            open: false,
          })
        }
        variant={openSnackbar.variant}
        message={openSnackbar.message}
        status={`${openSnackbar.status}`}
      />
    </div>
  );
};

export default CampaignSettingsDialog;
