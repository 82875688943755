import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';
import { useAuth0 } from 'config/react-auth0-spa';
import { Typography } from '@mui/material';
import useStyles from './breadCrumb-jss';

const Breadcrumbs = (props) => {
  const { accessTokenClaims } = useAuth0();
  const { classes, cx } = useStyles();
  const { theme, separator, location, additionalData, organizationName } =
    props;
  return (
    <section
      className={cx(
        theme === 'dark' ? classes.dark : classes.light,
        classes.breadcrumbs
      )}
    >
      <Route
        path="*"
        render={() => {
          let parts = location.pathname.split('/');
          const place = parts[parts.length - 1];
          parts = parts.slice(1, parts.length - 1);

          const CampaignFlowPlaceCheck = () => {
            if (
              place.length >= 30 &&
              (parts[parts.length - 1] === 'campaigns' ||
                parts[parts.length - 1] === 'analytics')
            ) {
              return true;
            }
            return false;
          };
          const isCampaignFlow = CampaignFlowPlaceCheck();

          return (
            <>
              <Typography component={'p'}>
                You are here:
                <span className={classes.capitalize}>
                  {parts.map((part, partIndex) => {
                    const path = ['', ...parts.slice(0, partIndex + 1)].join(
                      '/'
                    );
                    const isDatabaseManager = path.includes('database-manager');
                    return (
                      <Fragment key={path}>
                        {accessTokenClaims.roleId === '1' &&
                        !isDatabaseManager ? (
                          <Link to={`${path}s`}>{`${part}s`}</Link>
                        ) : (
                          <Link to={path}>{part}</Link>
                        )}
                        {separator}
                      </Fragment>
                    );
                  })}
                  &nbsp;
                  {isCampaignFlow ? (
                    <>
                      <Fragment key={additionalData.workspace}>
                        <Link
                          to={`/campaigns?workspace=${additionalData.workspace}`}
                        >
                          {additionalData.workspace}
                        </Link>
                        {separator}
                      </Fragment>{' '}
                      <span className={classes.nonCapitalize}>
                        {additionalData.name}
                      </span>
                    </>
                  ) : (
                    <>
                      {organizationName ||
                        place ||
                        (accessTokenClaims.roleId === '9'
                          ? 'Analytics'
                          : 'Dashboard')}
                    </>
                  )}
                </span>
              </Typography>
            </>
          );
        }}
      />
    </section>
  );
};

Breadcrumbs.propTypes = {
  location: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  separator: PropTypes.string.isRequired,
  additionalData: PropTypes.object.isRequired,
};

export default Breadcrumbs;
