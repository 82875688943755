import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { useAuth0 } from 'config/react-auth0-spa';

const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const { isAuthenticated, logout, user } = useAuth0();

  const handleMenu = (menu) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const AuthLogout = () => {
    const shouldLogout = () => {
      if (isAuthenticated) {
        logout({ returnTo: process.env.PLATFORM_FRONTEND_URL });
      }
    };

    return (
      <Button
        size="small"
        color="secondary"
        onClick={shouldLogout}
        style={{ padding: 0 }}
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <Typography variant="button" style={{ margin: 0 }}>
          Log Out
        </Typography>
      </Button>
    );
  };

  return (
    <div>
      <Button onClick={handleMenu('user-setting')}>
        <Avatar src={user ? user.picture : avatarApi[11]} />
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenu === 'user-setting'}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/user-profile">
          My Profile
        </MenuItem>
        <Divider />
        <MenuItem>
          <AuthLogout />
        </MenuItem>
      </Menu>
    </div>
  );
};

UserMenu.propTypes = {
  dark: PropTypes.bool,
};

UserMenu.defaultProps = {
  dark: false,
};

export default UserMenu;
