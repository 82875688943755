import React from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-modal/styles.css';
import { SnackbarContent, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import green from '@mui/material/colors/green';
import CloseIcon from '@mui/icons-material/Close';
import { tss } from 'tss-react/mui';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

const useStyles = tss.create(({ theme }) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
    color: 'black',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledSnackBarContent = React.forwardRef((props, ref) => {
  const { classes, cx } = useStyles();
  const { message, onClose, variant, className, status, ...other } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      ref={ref}
      className={cx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={cx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
});

StyledSnackBarContent.displayName = 'StyledSnackBarContent';

StyledSnackBarContent.propTypes = {
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StyledSnackBarContent.defaultProps = {
  onClose: () => {},
};

export default StyledSnackBarContent;
