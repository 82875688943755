import React from 'react';
import SnackbarWrapper from '@mui/material/Snackbar';
import StyledSnackBarContent from 'dan-components/Snackbar/StyledSnackBarContent';
import Portal from '@mui/material/Portal';
import PropTypes from 'prop-types';

const Snackbar = (props) => {
  const {
    open,
    variant,
    message,
    status,
    onClose,
    autoHideDuration,
    ContentProps,
    action,
    ...other
  } = props;

  return (
    <Portal>
      <SnackbarWrapper
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        ContentProps={ContentProps}
        action={action}
      >
        <StyledSnackBarContent
          onClose={onClose}
          variant={!variant ? 'error' : variant}
          message={message}
          status={status}
        />
      </SnackbarWrapper>
    </Portal>
  );
};
Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  message: PropTypes.node.isRequired,
  status: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
  ContentProps: PropTypes.object,
  action: PropTypes.node,
};
Snackbar.defaultProps = {
  autoHideDuration: 6000,
  ContentProps: {},
  action: '',
};
export default Snackbar;
