/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react18-router';
import FontFaceObserver from 'fontfaceobserver';
import history from 'utils/history';
import 'react-18-image-lightbox/style.css';
import 'sanitize.css/sanitize.css';

// Import Auth0 Provider
import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import { Auth0Provider } from './config/react-auth0-spa';

// Import root app
import './styles/layout/base.scss';

// Import Language Provider

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!../public/favicons/favicon.ico'; // eslint-disable-line
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line

import configureStore from './redux/configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const { store, persistor } = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const root = createRoot(MOUNT_NODE);
const render = (messages) => {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LanguageProvider messages={messages}>
          <Auth0Provider
            domain={process.env.DOMAIN}
            client_id={process.env.CLIENTID}
            redirect_uri={window.location.origin}
            audience={process.env.AUDIENCE}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            cacheLocation="localstorage"
          >
            <ConnectedRouter history={history}>
              <App history={history} />
            </ConnectedRouter>
          </Auth0Provider>
        </LanguageProvider>
      </PersistGate>
    </Provider>
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    // ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ])
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
