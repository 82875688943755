import React from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Loading from 'dan-components/Loading';
import Dashboard from './Dashboard';

import {
  CampaignsPage,
  CampaignFlow,
  Users,
  Profile,
  Workspaces,
  CampaignAnalyticsPage,
  CampaignsAnalyticsPage,
  NotFound,
  DashboardPage,
  ResourcesManagement,
  ResourcesPage,
  OrganizationsPage,
  Organization,
  OrganizationSA,
  RenderDataPreview,
  DatabaseManagerPage,
} from '../pageListAsync';
import { useAuth0 } from '../../config/react-auth0-spa';

const Application = (props) => {
  const { history } = props;
  const { loading, accessTokenClaims } = useAuth0();

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Dashboard history={history}>
          <Switch>
            {/* Home */}
            <Route
              exact
              path="/"
              component={
                accessTokenClaims.roleId === '9'
                  ? CampaignsAnalyticsPage
                  : DashboardPage
              }
            />

            {/* Pages */}
            <Route exact path="/users" component={Users} />
            <Route exact path="/user-profile" component={Profile} />
            <Route exact path="/workspaces" component={Workspaces} />

            {/* Analytics */}
            <Route
              exact
              path="/analytics/:campaignId"
              component={CampaignAnalyticsPage}
            />
            <Route path="/analytics" component={CampaignsAnalyticsPage} />

            {/* Campaigns */}
            <Route
              exact
              path="/campaigns/:campaignId"
              component={CampaignFlow}
            />
            <Route exact path="/campaigns/notfound" component={NotFound} />
            <Route exact path="/campaigns" component={CampaignsPage} />
            <Route
              exact
              path="/campaigns/:campaignId/:fileName"
              component={RenderDataPreview}
            />
            <Route
              exact
              path="/resources-management"
              component={ResourcesManagement}
            />
            <Route
              path="/resources/:folderName/:fileName"
              component={ResourcesPage}
            />
            <Route path="/resources/:fileName" component={ResourcesPage} />

            {/* Organizations */}
            <Route exact path="/organizations" component={OrganizationsPage} />
            <Route path="/database-manager" component={DatabaseManagerPage} />
            <Route
              exact
              path="/organization/:organizationId"
              component={OrganizationSA}
            />

            {/* Organization */}
            {/* <Route exact path="/organizations" component={OrganizationsPage} /> */}

            {/* Default */}
            <Route component={NotFound} />
          </Switch>
        </Dashboard>
      )}
    </>
  );
};

Application.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Application;
