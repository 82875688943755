import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { ErrorWrap } from 'dan-components';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({}));

const NotFoundDedicated = (props) => {
  const { gradient } = props;
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(
        classes.appFrameOuter,
        gradient ? classes.gradientBg : classes.solidBg
      )}
    >
      <main className={classes.outerContent} id="mainContent">
        <div className={classes.petal} />
        <ErrorWrap title="404" desc="Oops, Page Not Found :(" />
      </main>
    </div>
  );
};

NotFoundDedicated.propTypes = {
  gradient: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  gradient: state.ui.gradient,
});

const NotFoundDedicatedMaped = connect(mapStateToProps)(NotFoundDedicated);

export default NotFoundDedicatedMaped;
